import React, {useEffect, useState, useContext} from 'react'
import './style.scss'

import { Parallax } from 'react-scroll-parallax'

import { GatsbyImage } from 'gatsby-plugin-image'
import SanityOverlay from '../SanityOverlay'

import Intro from '../../animations/Intro'
import { useInView } from 'react-intersection-observer'

import FSLightBoxContext from '../../context/FSLightBoxContext'
import PortableText from 'react-portable-text'

function SanityImage(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const lightBoxContext = useContext(FSLightBoxContext)
  const {
		//id,
		slug,
		title,
		classes,
		lightboxOn,
		aspectRatio,
		aspectRatioResponsive,
    inheritedAspectRatio,
    inheritedAspectRatioResponsive,
		parallaxFrom = 0,
		parallaxTo = 0,
		_rawCaption: caption,
		captionClasses,
		image,
		imageResponsive,
		fit = 'cover',
		quickHTML,
		//skipIntro,
		//aspectOff,
		inheritedDelay,
		skipAnimation
	} = props


	//console.log('SanityImage:', props, title)

	// General Settings
	const responsiveBreakpoint = 1024
	const [windowSize, setWindowSize] = useState({width: null, height: null})

	// Take care of parallax and image alternative modes for responsive
	useEffect(() => {
		// RAF to update parallax position, it gets lost sometimes otherwise, especially on page changes
		// window.requestAnimationFrame(() => {
		// 	props.parallaxController.update()
		// })

		// Checking window size, dropping values into state
		function updateSize() {
			setWindowSize({width: window.innerWidth, height: window.innerHeight})
		}
		window.addEventListener('resize', updateSize)
		updateSize()

		// Kill off listener
		return () => window.removeEventListener('resize', updateSize)
	},[])

	// Image
	const imageAlt = title ? title : ''
	const extension = image?.asset?.extension
	// Image
	const imageResponsiveAlt = title ? title : ''
	const extensionResponsive = image?.asset?.extension
	// Lightbox
	const imgFullSizeUrl = image?.asset?.url
	//const imgFullSizeUrlResponsive = imageResponsive?.asset?.url
	// In Animation Sequence
	let in_ = { fade: 500 }

	// Look if the classes specify if the image is supposed to have decor
	let decor = classes?.search('decor')
	
	function thumbClicked(lightboxSlidesArray) {
		if(lightboxOn) {
			lightBoxContext.setSources(() => lightboxSlidesArray)
			setTimeout(() => {
				lightBoxContext.setToggler((toggle) => !toggle)
			}, 50)
		}
	}

	// If the classes on block have "t" (transparent bg), then animate bg since we know there is contrasting color going on
	if (classes?.indexOf(' t') > -1) {
		in_ = { bg: 500, fade: 500 }
	}

	let extendedClasses
	if (classes?.indexOf('superwide') > -1) {
		extendedClasses = 'padd c5 avoidable'
	}

	const ar = inheritedAspectRatio ? inheritedAspectRatio : aspectRatio
	const arr = inheritedAspectRatioResponsive ? inheritedAspectRatioResponsive : aspectRatioResponsive

	// Returning Section
  return image ? (

		<div
      ref={io}
      className={`image-wrap ${classes} ${extendedClasses} ${lightboxOn ? 'hover-trigger' : ''} ${(ar && image) || (arr && imageResponsive) ? 'aspect-ratio' : ''} ${extension === 'png' || extensionResponsive === 'png' ? 'png' : ''}`}
      onClick={() => thumbClicked([imgFullSizeUrl])}
      aria-label="Lightbox trigger"
      onKeyDown={() => thumbClicked([imgFullSizeUrl])}
      role="button"
      tabIndex={0}
      style={{
				paddingBottom: ar && image && (windowSize.width >= responsiveBreakpoint) ? ar + '%' : arr && (windowSize.width < responsiveBreakpoint) ? arr + '%' : null
			}}
    >

			{slug?.current ?
				<div id={`block-${slug.current}`} className="anchor"></div>
			: null}

			{ parallaxFrom && parallaxFrom !== 0 && parallaxTo && parallaxTo !== 0 ?
				<Intro skipAnimation={skipAnimation} visible={ioInView} in={in_} delayIn={inheritedDelay} mounted={true} stay={true} className="has-parallax">
					<Parallax className="parallax" translateY={[parallaxFrom, parallaxTo]}>
						{ !imageResponsive || windowSize.width >= responsiveBreakpoint ?
              <div className='parallax-inner' style={{
								height: 100 + (Math.abs(parallaxFrom) + Math.abs(parallaxTo)) * 0.5 + '%'
							}}>
							  <GatsbyImage image={image?.asset?.gatsbyImageData} alt={imageAlt} objectFit={fit} />
              </div>
						: null }
						{ imageResponsive && windowSize.width < responsiveBreakpoint ?
              <div className='parallax-inner' style={{
								height: 100 + (Math.abs(parallaxFrom) + Math.abs(parallaxTo)) * 0.5 + '%'
							}}>
                <GatsbyImage image={imageResponsive?.asset?.gatsbyImageData} alt={imageResponsiveAlt} objectFit={fit} />
              </div>
						: null }
					</Parallax>
				</Intro>
			:
				<>
					{ !imageResponsive || windowSize.width >= responsiveBreakpoint ?
						<Intro skipAnimation={skipAnimation} visible={ioInView} in={in_} delayIn={inheritedDelay} mounted={true} stay={true}>
							<GatsbyImage image={image?.asset?.gatsbyImageData} alt={imageAlt} objectFit={fit} />
						</Intro>
					: null }
					{ imageResponsive && windowSize.width < responsiveBreakpoint ?
						<Intro skipAnimation={skipAnimation} visible={ioInView} in={in_} delayIn={inheritedDelay} mounted={true} stay={true}>
							<GatsbyImage image={imageResponsive?.asset?.gatsbyImageData} alt={imageResponsiveAlt} objectFit={fit} />
						</Intro>
					: null }
				</>
			}

			<SanityOverlay {...props} />

			{
				caption ? 
					<div className={`caption-img ${captionClasses ? captionClasses : ''}`}>
						<PortableText content={caption} serializers={{
							blockHTML: (props) => <div
								dangerouslySetInnerHTML={{ __html: props.code }}
							/>,
						}} />
					</div>
				:
				null
			}

			{decor >= 0 ?
				<div className="decor-wrap">
					<Intro visible={ioInView} in={{fade: 3000}} delayIn={inheritedDelay} mounted={true} stay={true} className="c5 t">
						<div className="decor-inside c4"></div>
					</Intro>
				</div>
			: null }

			{quickHTML?.code ?
				<div
					dangerouslySetInnerHTML={{ __html: quickHTML.code }}
				/>
			: null }

		</div>

  ) : false
}

export default SanityImage