import React from 'react'
import './style.scss'

import Intro from '../../animations/Intro'
import { useInView } from 'react-intersection-observer'

import SanityImage from '../SanityImage'
import SanityVideo from '../SanityVideo'
import SanityContent from '../SanityContent'

import SlickSlider from 'react-slick'
import { getGatsbyImageData } from 'gatsby-source-sanity'

function SanitySlider(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
  const {
		id,
		slug,
		//title,
		classes,
		slides,
		aspectRatio,
		aspectRatioResponsive,
		quickHTML,
		inheritedDelay,
    skipAnimation
	} = props

  const sanityProjectId = process.env.GATSBY_SANITY_PROJECT_ID
  const sanityDataset = process.env.GATSBY_SANITY_DATASET

	// In Animation Sequence
	let in_ = { fade: 500 }

	// Slider
	//const [refSlider, refSliderInView] = useInView({ triggerOnce: true })

  const SliderArrowNext = ({to, onClick}) => {
    return (
      <div role="button" tabIndex={-100} onClick={onClick} onKeyDown={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        {/* <div className="text">NEXT</div> */}
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({to, onClick}) => {
    return (
      <div role="button" tabIndex={-100} onClick={onClick} onKeyDown={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
        {/* <div className="text">PREVIOUS</div> */}
      </div>
    )
  }

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SliderArrowNext to="next"/>,
    prevArrow: <SliderArrowPrev to="prev"/>,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
	}


  const nodes = slides?.map((node,i) => {
		let slide = node
    slide.skipIntro = true
		//console.log('Slide:', slide.content)

    // Prepare Image Slide, be ready for WYSIWYG Blocks
    const image = slide._type === 'builderImage' ?
      <SanityImage
        key={`image-${i}`}
        {...node}
        inheritedAspectRatio={aspectRatio}
        inheritedAspectRatioResponsive={aspectRatioResponsive}
        image={{asset: {gatsbyImageData: getGatsbyImageData(slide?.image?.asset._id, {}, {projectId: sanityProjectId, dataset: sanityDataset})}}}
        imageResponsive={{asset: {gatsbyImageData: getGatsbyImageData(slide?.imageResponsive?.asset._id, {}, {projectId: sanityProjectId, dataset: sanityDataset})}}}
      />
    : node.__typename === 'SanityBuilderImage' ?
      <SanityImage key={`image-${i}`} {...node} inheritedAspectRatio={aspectRatio} inheritedAspectRatioResponsive={aspectRatioResponsive} />
    : null

    // Prepare Video Slide, be ready for WYSIWYG Blocks
    const video = slide._type === 'builderVideo' ?
      <SanityVideo
        key={`video-${i}`}
        {...slide}
        inheritedAspectRatio={aspectRatio}
        inheritedAspectRatioResponsive={aspectRatioResponsive}
        image={{asset: {gatsbyImageData: getGatsbyImageData(slide?.image?.asset._id, {}, {projectId: sanityProjectId, dataset: sanityDataset})}}}
        imageResponsive={{asset: {gatsbyImageData: getGatsbyImageData(slide?.imageResponsive?.asset._id, {}, {projectId: sanityProjectId, dataset: sanityDataset})}}}
      />
    : slide.__typename === 'SanityBuilderVideo' ?
      <SanityVideo key={`video-${i}`} {...slide} inheritedAspectRatio={aspectRatio} inheritedAspectRatioResponsive={aspectRatioResponsive} />
    : null

    // Prepare Content Slide, be ready for WYSIWYG Blocks
    const content = slide.content && slide._type === 'builderContent' ?
      <SanityContent key={`content-${i}`} {...slide} />
    : slide.__typename === 'SanityBuilderContent' ?
      <SanityContent key={`content-${i}`} {...slide} />
    : null

    return (
      <div key={i} className="slide">
				{image}
        {video}
    		{content}
      </div>
    )
  })

  const slider = 
    <SlickSlider {...settings}>
      {nodes}
    </SlickSlider>
  

	// Returning Section
  return (

		<div
      key={id}
      ref={io}
      className={`slider-wrap ${classes}`}
    >

			{slug?.current ?
				<div id={`block-${slug.current}`} className="anchor"></div>
			: null}

			{slides ?
				<Intro skipAnimation={skipAnimation} visible={ioInView} in={in_} delayIn={inheritedDelay} mounted={true} stay={true}>
					{slider}
				</Intro>
			: null }

			{quickHTML?.code ?
				<div
					dangerouslySetInnerHTML={{ __html: quickHTML.code }}
				/>
			: null }

		</div>

  )
}

export default SanitySlider