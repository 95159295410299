import React from 'react'
import './style.scss'

import Intro from '../../animations/Intro'
import { useInView } from 'react-intersection-observer'

import PortableText from 'react-portable-text'
import { Link } from 'gatsby'

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function SanityContent(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const {
    id,
    slug,
    //title,
    classes,
    _rawContent: content,
    content: deepContent,
    quickHTML,
    inheritedDelay,
    skipAnimation
  } = props


  // In Animation Sequence
  let in_ = { fade: 500 }


  // Returning Section
  return (

    <div
      key={id}
      ref={io}
      className={`content-wrap ${classes}`}
    >

      {slug?.current ?
        <div id={`block-${slug.current}`} className="anchor"></div>
        : null}

      {content || deepContent ?
        <Intro skipAnimation={skipAnimation} visible={ioInView} in={in_} delayIn={inheritedDelay} mounted={true} stay={true}>
          <PortableText content={deepContent ? deepContent : content} serializers={{
            link: ({ href, children }) => {
            return validURL(href) || href.startsWith('tel:') ?
                <a href={href} target="_blank" rel="noreferrer noopener">{children}</a>
                : <Link to={href}>{children}</Link>
            },
            blockHTML: (props) => <div
              dangerouslySetInnerHTML={{ __html: props.code }}
            />,
          }} />
        </Intro>
        : null}

      {quickHTML?.code ?
        <div
          dangerouslySetInnerHTML={{ __html: quickHTML.code }}
        />
        : null}

    </div>

  )
}

export default SanityContent